<template>
  <div class="white_bg border_radius mb-3" @click="signup">
    <div class="row align-items-center p-4">
      <div class="col-auto">
        <IconRound size="big" align="center" >
          <IconRegister color="gold" size="size28"></IconRegister>
        </IconRound>
      </div>
      <div class="col">
        <div class="green-text-dark medium mb-2">
          Donating for the First Time? Register a Donor Profile <span><IconArrowForward color="darkgreen" size="size16" inline="inline" /></span>
        </div>
        <div class="font12">
          Register your donor profile for quick & easy donations, donation history, regular payments, and GiftAid
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { login } from '@/api/user'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconRound: defineAsyncComponent(() => import('@/components/IconRound.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconRegister: defineAsyncComponent(() => import('@/components/icons/IconRegister.vue'))
  },
  name: 'DonorSignupButton',
  methods: {
    signup () {
      this.$emit('signup')
    }
  }
}
</script>

<style scoped>
.border_radius {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.04) 7px 7px 15px 0px;
  cursor: pointer;
}
</style>
